<template>
    <div v-if="areLoading" class="borrower-main borrower-main__loading">
        <b-loading v-model="areLoading" type="is-primary" is-full-page></b-loading>
    </div>
    <confirm-address
        v-else-if="progress === 'appointment-pending' && confirmAddresVisible"
        class="confirm-address"
        @on-hide="confirmAddresVisible = false"
    />
    <div v-else class="borrower-container">
        <side-overview
            v-if="$vssWidth > 1000"
            :borrower-data="borrowerData"
            :current-step="currentStep"
        />
        <div class="borrower-container__main">
            <div v-if="isPaymentError" class="payment-error">
                <div class="payment-error__icon">
                    <img src="@/assets/icons/ic-warning.svg" alt="" />
                </div>
                <div class="payment-error__text">
                    {{ $t('valuer_tool.borrower.payment-error') }}
                </div>
            </div>
            <main-layout
                v-if="currentStep && !isLoading"
                :borrower-data="borrowerData"
                :current-step="currentStep"
                :next-step="nextStep"
                :prev-step="prevStep"
                @update:appointment="updateAppointment"
                @update:payment="updatePayment"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BorrowerDetails from './BorrowerDetails.vue'
import ConfirmAddress from './ConfirmAddress.vue'
import AppointmentMaking from './AppointmentMaking.vue'
import Payment from './Payment.vue'
import BookingComplete from './BookingComplete.vue'
import SideOverview from './SideOverview.vue'
import MainLayout from './MainLayout.vue'
import AppointmentOverview from './AppointmentOverview.vue'
import PaymentOverview from './PaymentOverview.vue'
import utils from '@/shared/plugins/utils'

export default {
    name: 'BorrowerOVMRequest',
    components: {
        SideOverview,
        MainLayout,
        ConfirmAddress,
    },
    data: () => ({
        steps: [
            {
                step: 'appointment-pending',
                component: AppointmentMaking,
                progress: 0,
            },
            {
                step: 'borrower-details',
                component: BorrowerDetails,
                progress: 1,
            },
            {
                step: 'confirm-appointment',
                component: AppointmentOverview,
                progress: 2,
            },
            {
                step: 'confirm-payment',
                component: PaymentOverview,
                progress: 3,
            },
            {
                step: 'payment-pending',
                component: Payment,
                progress: 3,
            },
            {
                step: 'confirmation-pending',
                component: BookingComplete,
                progress: 4,
            },
            {
                step: 'valuation-pending',
                component: BookingComplete,
                progress: 4,
            },
            {
                step: 'valued',
                component: BookingComplete,
                progress: 4,
            },
        ],
        borrowerData: {
            appointment: null,
            payment: null,
            userData: null,
        },
        confirmAddresVisible: null,
        isLoading: true,
        isPaymentError: false,
    }),
    computed: {
        ...mapGetters('valuationStore', [
            'ovm_status',
            'getRequestRef',
            'ovmInfo',
            'borrower',
        ]),
        ...mapGetters('valuationStore/Address', ['getAddress', 'isRequestLoaded']),
        ...mapGetters('auth', ['userId', 'hasRole']),
        areLoading() {
            return this.isLoading || !this.isRequestLoaded
        },
        user_id() {
            return this.borrower.id
        },
        currentStepIndex() {
            return this.steps.map(({ step }) => step).indexOf(this.step)
        },
        nextStep() {
            return this.steps[this.currentStepIndex + 1]?.step
        },
        prevStep() {
            if (this.currentStepIndex === 0) {
                return null
            } else {
                return this.steps[this.currentStepIndex - 1].step
            }
        },
        currentStep() {
            return this.steps.find(({ step }) => step === this.step)
        },
        step() {
            return this.$route.query.step
        },
        progress() {
            const { appointment, userData, ovm_info, payment } = this.borrowerData
            if (!appointment) {
                return 'appointment-pending'
            }
            if (userData && !userData.first_name) {
                return 'borrower-details'
            }
            if (payment && payment.status === 'succeeded' && !payment.is_payment_needed) {
                return 'confirmation-pending'
            }
            if (ovm_info && !ovm_info.will_be_present) {
                return 'confirm-payment'
            } else {
                return 'confirm-appointment'
            }
        },
    },
    watch: {
        isRequestLoaded: {
            handler(requestLoaded) {
                if (requestLoaded && this.borrower) {
                    this.borrowerData.ovm_info = {
                        will_be_present: this.ovmInfo?.will_be_present ?? true,
                        access_instructions: this.ovmInfo?.access_instructions || null,
                        representative: this.ovmInfo?.representative || {
                            full_name: null,
                            phone: null,
                            role: null,
                        },
                    }
                    const { email, first_name, last_name, phone } = this.borrower

                    this.borrowerData.userData = {
                        phone,
                        first_name,
                        last_name,
                        email,
                    }

                    const {
                        streetname,
                        streetnumber,
                        postalcode,
                        boxnumber,
                        municipality,
                    } = this.getAddress

                    if (!this.getAddress) return

                    this.borrowerData.address = {
                        boxnumber,
                        streetname,
                        streetnumber,
                        postalcode,
                        municipality,
                    }
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.initBorrowerData()
        if (!this.step) {
            this.$router.replace({ query: { ...this.$route.query, step: this.progress } })
        }
        if (this.$route.query?.redirect_status === 'failed') {
            this.isPaymentError = true
        }
    },
    methods: {
        initBorrowerData() {
            this.isLoading = true
            Promise.all([this.updateAppointment(), this.updatePayment()]).finally(() => {
                if (this.progress === 'appointment-pending') {
                    this.confirmAddresVisible = true
                }
                this.isLoading = false
            })
        },
        updatePayment() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'payment',
                'request',
                this.getRequestRef,
                'summary',
            ])

            return this.$axios.get(url).then(({ data: payment }) => {
                if (!payment) return

                const { total_fee_euro, status, is_payment_needed, receipt_url } = payment
                const vat = total_fee_euro - total_fee_euro / 1.21
                const subtotal = total_fee_euro - vat
                this.borrowerData.payment = {
                    total: +total_fee_euro,
                    vat,
                    subtotal,
                    status,
                    is_payment_needed,
                    receipt_url,
                }
            })
        },
        getUser() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'user',
                this.user_id,
            ])
            return this.$axios.get(url)
        },
        updateAppointment() {
            let url = ''
            if (this.getRequestRef) {
                url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                    'appointment',
                    'request',
                    this.getRequestRef,
                ])
            } else {
                url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                    'appointment',
                    'user',
                    this.$route.query.id || this.userId,
                ])
            }
            return this.$axios.get(url).then(({ data: appointments }) => {
                if (!appointments.length) return
                this.borrowerData.appointment = appointments
                    .map((rdv) => ({
                        date: rdv.start_time,
                        start_time: new Date(rdv.start_time),
                        end_time: new Date(rdv.end_time),
                        status: rdv.status,
                    }))
                    .filter(
                        (appointment) =>
                            !['cancelled', 'rts_requested', 'new'].includes(
                                appointment.status
                            )
                    )[0]
            })
        },
    },
}
</script>

<style scoped lang="scss">
.borrower-container {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    padding-inline: 7.5rem;
    padding-block: 5rem;
    background: #f9fafc;
    min-height: 100%;
    overflow: auto;

    &__main {
        display: flex;
        gap: 1.5rem;
        flex-direction: column;
    }
    @media screen and (max-width: 999px) {
        padding: 0;
    }
}
.payment-error {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    flex: 1 0 0;
    min-width: 100%;
    justify-content: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #fa0;
    background: rgba(255, 170, 0, 0.2);

    &__icon {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.52081rem;
        justify-content: center;
        align-items: center;
        gap: 0.52081rem;
        border-radius: 4.16669rem;
        background: rgba(255, 170, 0, 0.2);
    }
    &__text {
        color: #001837;
        text-align: center;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}
</style>
