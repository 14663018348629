<template>
    <div class="ers-page-content">
        <div class="ers-page-title">
            <h1>
                {{ $t('energy.consumers.title') }}
            </h1>
        </div>

        <div class="ers-page-title">
            <h2>
                {{ $t('energy.consumers.question_1a') }}
                <Tooltip
                    class="ers-page-title-tooltip"
                    :label="$t('energy.consumers.tooltip_1')"
                />
            </h2>
        </div>

        <div class="ers-page-section">
            <people-select v-model="n_residents" :max="12" />
        </div>

        <div v-if="n_residents > 0" class="ers-page-consumption">
            <div v-if="getFeatures.energy_consumption_known" class="ers-page-section">
                <h2>
                    {{ $t('energy.consumers.question_2a') }}
                    <Tooltip
                        class="ers-page-title-tooltip"
                        :label="$t('energy.consumers.tooltip_2')"
                    />
                </h2>

                <info-bubble :text="$t('energy.consumers.bubble_info')" />

                <div class="section-inputs">
                    <div class="consumption-input-field">
                        <b-field
                            class="section-inputs-item"
                            :label="$t(`value.${getHeatingFuel}`)"
                            :type="{
                                'is-danger':
                                    $v.fuel.amount.$invalid && $v.fuel.amount.$dirty,
                            }"
                            :message="[
                                !$v.fuel.amount.inRange
                                    ? $t('validation.range', ranges[getValidationType])
                                    : null,
                                !$v.fuel.amount.numeric
                                    ? $t('validation.only_numbers')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="fuel.amount"
                                size="is-medium"
                                class="ers-input-field"
                                @input="$v.fuel.amount.$touch()"
                            />
                        </b-field>
                        <p
                            class="unit"
                            :class="{
                                'unit--danger':
                                    !$v.fuel.amount.inRange || !$v.fuel.amount.numeric,
                            }"
                        >
                            {{ $t(`unit.${fuel.unit}/year`) }}
                        </p>
                    </div>

                    <div
                        v-if="
                            !getIsFuelElectricity && getHeatingFuel !== 'district_heating'
                        "
                        class="section-radios"
                        :class="{
                            'section-radios--invalid':
                                $v.fuel.amount.$invalid && $v.fuel.amount.$dirty,
                        }"
                    >
                        <b-radio-button
                            v-model="fuel.unit"
                            :native-value="canonical_consumption_unit(getHeatingFuel)"
                            size="is-medium"
                            class="ers-radio-start"
                        >
                            <span>
                                {{
                                    $t(
                                        `unit.${canonical_consumption_unit(
                                            getHeatingFuel
                                        )}/year`
                                    )
                                }}
                            </span>
                        </b-radio-button>
                        <b-radio-button
                            v-model="fuel.unit"
                            native-value="kwh"
                            size="is-medium"
                            class="ers-radio-end"
                        >
                            <span>{{ $t('unit.kwh/year') }}</span>
                        </b-radio-button>
                    </div>

                    <div
                        v-if="!getIsFuelElectricity"
                        class="consumption-input-field electricity"
                    >
                        <b-field
                            class="section-inputs-electricity"
                            :label="$t('value.electricity')"
                            :type="{
                                'is-danger':
                                    $v.electricity.amount.$invalid &&
                                    $v.electricity.amount.$dirty,
                            }"
                            :message="[
                                !$v.electricity.amount.inRange
                                    ? $t('validation.range', ranges['electricity'])
                                    : null,
                                !$v.electricity.amount.numeric
                                    ? $t('validation.only_numbers')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="electricity.amount"
                                size="is-medium"
                                class="ers-input-field"
                                @input="$v.electricity.amount.$touch()"
                            />
                        </b-field>
                        <p
                            class="unit"
                            :class="{
                                'unit--danger':
                                    !$v.electricity.amount.inRange ||
                                    !$v.electricity.amount.numeric,
                            }"
                        >
                            {{ $t('unit.kwh/year') }}
                        </p>
                    </div>
                </div>
            </div>

            <div
                v-if="!getIsFuelElectricity && !getFeatures.energy_consumption_known"
                class="ers-page-section"
            >
                <div class="ers-page-title">
                    <h2>
                        {{ $t('energy.consumers.question_1b') }}
                        <Tooltip
                            class="ers-page-title-tooltip"
                            :html="
                                $t(
                                    'energy.consumers.tooltip_popup_1',
                                    getFuelConsumptionData
                                )
                            "
                        />
                    </h2>
                </div>
                <multiple-choice v-model="fuel.estimate" :answers="levels()" center />
            </div>

            <div
                v-if="
                    (getIsFuelElectricity || fuel.estimate) &&
                        !getFeatures.energy_consumption_known
                "
                class="ers-page-section"
            >
                <h2>
                    {{ $t('energy.consumers.question_2b') }}
                    <Tooltip
                        class="ers-page-title-tooltip"
                        :html="
                            $t('energy.consumers.tooltip_popup_2', getElecConsumptionData)
                        "
                    />
                </h2>
                <multiple-choice
                    v-model="electricity.estimate"
                    :answers="levels()"
                    center
                />
            </div>
        </div>

        <div class="column is-12 mt-2">
            <div class="is-flex is-justify-content-space-between">
                <b-button
                    size="is-medium"
                    type="is-dark"
                    icon-left="arrow-left"
                    @click="changePage(false)"
                >
                    {{ $t('common.previous') }}
                </b-button>
                <submit-button
                    v-if="showNextButton"
                    @on-click="
                        $emit('open-summary-modal', {
                            unsubmit: false,
                        })
                        changePage(true)
                    "
                />
                <b-button
                    v-if="showNextButton"
                    :class="{ 'ers-next-bttn-dark': hasRequestBeenUnsubmitted }"
                    size="is-medium"
                    type="is-primary"
                    icon-right="arrow-right"
                    @click="changePage(true)"
                >
                    <span>{{ $t('common.next') }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import PeopleSelect from '../../elements/PeopleSelect.vue'
import MultipleChoice from '../../elements/MultipleChoice.vue'
import SubmitButton from '../../elements/SubmitButton.vue'
import { numeric } from 'vuelidate/lib/validators'
import Tooltip from '../../elements/Tooltip.vue'
import utils from '@/shared/plugins/utils'
import InfoBubble from '@/components/elements/InfoBubble.vue'

export default {
    name: 'Consumers',
    components: {
        PeopleSelect,
        SubmitButton,
        MultipleChoice,
        Tooltip,
        InfoBubble,
    },
    props: {
        proceedFn: {
            type: Function,
            required: false,
            default: null,
        },
        cancelFn: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data: () => ({
        n_residents: 0,
        isFirstPass: true,
        messages: {
            heating: [],
            electricity: [],
        },
        fuel: {
            estimate: null,
            amount: null,
            unit: null,
        },
        electricity: {
            estimate: null,
            amount: null,
            unit: null,
        },
        ranges: {
            kwh: {
                min: 2000,
                max: 50000,
            },
            gas: {
                min: 200,
                max: 4600,
            },
            oil: {
                min: 200,
                max: 5000,
            },
            pellets: {
                min: 400,
                max: 10500,
            },
            wood: {
                min: 1000,
                max: 5000,
            },
            coal: {
                min: 200,
                max: 5300,
            },
            electricity: {
                min: 2500,
                max: 40000,
            },
        },
    }),
    validations() {
        return {
            fuel: {
                amount: {
                    inRange: (val) => {
                        const { min, max } = this.ranges[this.getValidationType]
                        if (this.$v.fuel.amount.$dirty) {
                            return !(val < min || val > max)
                        } else {
                            return true
                        }
                    },
                    numeric,
                },
            },
            electricity: {
                amount: {
                    inRange: (val) => {
                        if (
                            !this.getIsFuelElectricity &&
                            this.$v.electricity.amount.$dirty
                        ) {
                            const { min, max } = this.ranges['electricity']
                            return !(val < min || val > max)
                        } else {
                            return true
                        }
                    },
                    numeric,
                },
            },
        }
    },
    computed: {
        ...mapGetters('ersStore', [
            'getFeatures',
            'getHeatingFuel',
            'getRegion',
            'getOtherData',
            'hasRequestBeenUnsubmitted',
            'getConsumptionKnown',
        ]),
        getValidationType() {
            if (this.fuel.unit === 'kwh' && !this.getIsFuelElectricity) {
                return 'kwh'
            } else {
                return this.getHeatingFuel
            }
        },
        showNextButton() {
            if (this.n_residents === 0 || this.$v.$invalid) return false
            if (
                (['electricity'].includes(this.getHeatingFuel) && this.fuel.estimate) ||
                this.fuel.amount ||
                this.electricity.estimate
            ) {
                return true
            }

            return false
        },
        getIsFuelElectricity() {
            return this.getHeatingFuel === 'electricity'
        },
        getFuelConsumptionData() {
            const heatingFuel =
                this.getHeatingFuel || this.getFeatures.heating_attributes[0].heating_type
            const {
                min: low_heat_cons,
                med: medium_heat_cons,
                max: high_heat_cons,
            } = utils.consumptionsData('kwh', this.getRegion, heatingFuel)
            return {
                low_heat_cons,
                medium_heat_cons,
                high_heat_cons,
            }
        },
        getElecConsumptionData() {
            const {
                min: low_elec_cons,
                med: medium_elec_cons,
                max: high_elec_cons,
            } = utils.consumptionsData(
                'kwh',
                this.getRegion,
                'electricity',
                this.getIsFuelElectricity
            )
            return {
                low_elec_cons,
                medium_elec_cons,
                high_elec_cons,
            }
        },
        types() {
            let types = []

            if (['gas'].includes(this.getHeatingFuel)) {
                types.push({
                    value: 'boiler:standard',
                })
            }

            if (['gas', 'oil'].includes(this.getHeatingFuel)) {
                types.push({
                    value: 'boiler:condensing',
                })
            }

            if (['gas', 'oil', 'wood', 'pellets', 'coal'].includes(this.getHeatingFuel)) {
                types.push({
                    value: 'stove',
                })
            }

            if (['electricity'].includes(this.getHeatingFuel)) {
                types.push({
                    value: 'heat_pump',
                })
                types.push({
                    value: 'electric_heater',
                })
            }
            return types
        },
        getElectricity() {
            return Object.assign({}, this.electricity)
        },
        getFuel() {
            return Object.assign({}, this.fuel)
        },
    },
    watch: {
        getElectricity: {
            // https://stackoverflow.com/questions/62729380/vue-watch-outputs-same-oldvalue-and-newvalue
            handler(newVal, oldVal) {
                if (newVal.estimate !== oldVal.estimate && this.isFirstPass) {
                    this.changePage(true)
                } else {
                    this.isFirstPass = true
                }
            },
            deep: true,
        },
        getFuel: {
            handler(newVal, oldVal) {
                if (
                    ['electricity'].includes(this.getHeatingFuel) &&
                    newVal.estimate !== oldVal.estimate &&
                    this.isFirstPass
                ) {
                    this.changePage(true)
                } else {
                    this.isFirstPass = true
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.fuel.unit = 'kwh'

        const {
            n_residents,
            known_consumptions,
            estimated_consumptions,
        } = this.getFeatures

        if (n_residents) {
            this.isFirstPass = false
            this.n_residents = n_residents
        }

        if (this.getIsFuelElectricity) {
            if (!this.getConsumptionKnown) {
                if (estimated_consumptions.length) {
                    this.electricity.estimate = estimated_consumptions[0].estimate
                }
            } else {
                if (known_consumptions.length) {
                    this.fuel.amount = known_consumptions[0].amount
                }
            }
        } else {
            if (!this.getConsumptionKnown) {
                estimated_consumptions.map((cons) => {
                    const estimate = cons.estimate
                    const fuelType = cons.consumption_fuel
                    if (fuelType === 'electricity') {
                        this.electricity.estimate = estimate
                    } else {
                        this.fuel.estimate = estimate
                    }
                })
            } else {
                known_consumptions.map((cons) => {
                    const amount = cons.amount
                    const fuelType = cons.consumption_fuel
                    if (fuelType === 'electricity') {
                        this.electricity.amount = amount
                    } else {
                        this.fuel.amount = amount
                        this.fuel.unit = cons.unit
                    }
                })
            }
        }
    },
    methods: {
        ...mapMutations('ersStore', ['SET_FEATURES']),
        ...mapMutations('ersStore', ['UPDATE_STATUS']),
        ...mapMutations('ersStore', ['SET_CONSUMPTION']),
        ...mapActions('ersStore', ['submit_features', 'set_and_submit_feature']),

        levels() {
            return [
                {
                    name: this.$t('value.low'),
                    value: 'low',
                },
                {
                    name: this.$t('value.medium'),
                    value: 'medium',
                },
                {
                    name: this.$t('value.high'),
                    value: 'high',
                },
            ]
        },
        canonical_consumption_unit(fuel) {
            switch (fuel) {
                case 'gas':
                    return 'm3'
                case 'oil':
                    return 'l'
                case 'wood':
                case 'pellets':
                case 'coal':
                    return 'kg'
                case 'district_heating':
                    return 'kwh'
                case 'electricity':
                    return 'kwh'
                default:
                    return ''
            }
        },
        changePage(isNextPage) {
            const nextPage = isNextPage ? 'ers.big-consumers' : 'ers.energy-consumption'
            if (this.getFeatures.energy_consumption_known) {
                this.SET_CONSUMPTION({
                    consumption: {
                        consumption_fuel: this.getHeatingFuel,
                        amount: +this.fuel.amount,
                        unit: this.fuel.unit || 'kwh',
                    },
                    consumption_known: true,
                })
                this.submit_features(nextPage)
                if (!['electricity'].includes(this.getHeatingFuel)) {
                    this.SET_CONSUMPTION({
                        consumption: {
                            consumption_fuel: 'electricity',
                            amount: +this.electricity.amount,
                            unit: this.canonical_consumption_unit('electricity'),
                        },
                        consumption_known: true,
                    })
                    this.submit_features(nextPage)
                }
            } else {
                if (this.getIsFuelElectricity) {
                    this.SET_CONSUMPTION({
                        consumption: {
                            consumption_fuel: 'electricity',
                            estimate: this.electricity.estimate,
                        },
                        consumption_known: false,
                    })
                    this.submit_features(nextPage)
                } else {
                    this.SET_CONSUMPTION({
                        consumption: {
                            consumption_fuel: this.getHeatingFuel,
                            estimate: this.fuel.estimate,
                        },
                        consumption_known: false,
                    })
                    this.SET_CONSUMPTION({
                        consumption: {
                            consumption_fuel: 'electricity',
                            estimate: this.electricity.estimate,
                        },
                        consumption_known: false,
                    })
                    this.submit_features(nextPage)
                }
            }

            const feature = {
                n_residents: this.n_residents,
            }

            this.set_and_submit_feature({
                feature,
                nextPage,
            })

            this.$router.push({
                name: nextPage,
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
    },
}
</script>

<style scoped lang="scss">
.ers-page-title {
    display: flex;
}

h2 {
    color: #001837;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
}

.unit {
    display: grid;
    align-items: center;
    z-index: 4;
    min-width: 4rem;
    transform: translateX(-150%) translateY(8%);
    &--danger {
        transform: translateX(-180%);
    }
}

.section-inputs {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
    min-width: 100%;
    &-item {
        flex-grow: 1;
        min-width: 100%;
    }
}

.section-radios {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0rem;
    margin-block-start: auto;
    margin-bottom: 0.99rem;
    margin-inline-start: auto;
    &--invalid {
        margin-bottom: 2.1rem;
    }
}

.ers-page-consumption {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.section-inputs-electricity {
    min-width: 100%;
}

.consumption-input-field {
    display: flex;
    flex-grow: 1;
    &.electricity {
        min-width: 100%;
    }
}
</style>

<style lang="scss">
.ers-page-title-tooltip {
    .ers-tooltip-icon {
        position: relative;
        transform: translateY(28%);
        margin-inline-start: 0.64rem;
    }
}

.ers-radio-start {
    label {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.ers-radio-end {
    label {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}
</style>
