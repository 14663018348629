import Vue from 'vue'
import { unref } from 'vue'
import { get, set, del } from 'idb-keyval'
import {
    experimental_createPersister,
    PERSISTER_KEY_PREFIX,
} from '@tanstack/query-persist-client-core'
import { hashKey } from '@tanstack/vue-query'
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'
import { cloneDeepUnref } from './utils/clone'

const baseUrl = (queryKey) => {
    if (queryKey[0] === 'valuation') {
        return Vue.prototype.$config.VALUATION_API_URL
    } else if (queryKey[0] === 'auth') {
        return Vue.prototype.$config.AUTH_API_URL
    } else {
        return '/'
    }
}

const defaultMutationFn = async ({ verb, queryKey, data }) => {
    // console.log('performing mutation:', { verb, queryKey, data })
    const path_segments = queryKey
        .map(unref)
        .filter((key) => typeof key !== 'object' && key !== 'valuation' && key !== 'auth')
    const last_key = unref(queryKey[queryKey.length - 1])
    const params = typeof last_key === 'object' ? last_key : {}
    const { data: returned_data } = await axios({
        method: verb,
        url: utils.urlJoin(baseUrl(queryKey), path_segments),
        params,
        data,
    })
    return returned_data
}

const defaultQueryFn = async ({ queryKey }) => {
    const path_segments = queryKey.filter(
        (key) => typeof key !== 'object' && key !== 'valuation' && key !== 'auth'
    )
    const params = queryKey.find((key) => typeof key === 'object') ?? {}
    if (!Vue.prototype.$config) {
        // TODO: Figure out a way to catch and handle TypeError exceptions globally
        throw new TypeError('DefaultQueryFn: Config has not been loaded yet')
    }
    const { data } = await axios.get(utils.urlJoin(baseUrl(queryKey), path_segments), {
        params,
    })
    return data
}

const storage = {
    getItem: (key) => get(key),
    setItem: (key, value) => set(key, value),
    removeItem: (key) => del(key),
}
const persister = experimental_createPersister({
    storage,
})
const vueQueryPluginOptions = {
    /*clientPersister: (queryClient) => {
        return persistQueryClient({
            queryClient,
            persister: experimental_createPersister({
                storage: {
                    getItem: (key) => get(key),
                    setItem: (key, value) => set(key, value),
                    removeItem: (key) => del(key),
                },
            }),
        })
    },
    clientPersisterOnSuccess: (queryClient) => {
        queryClient.resumePausedMutations()
    },*/
    queryClientConfig: {
        // provide the default query function with defaultOptions
        defaultOptions: {
            queries: {
                queryFn: defaultQueryFn,
                cacheTime: 1000 * 60 * 60 * 24 * 30, // 30 days
                persister,
            },
            mutations: {
                mutationFn: defaultMutationFn,
            },
        },
    },
}

const persistQuery = function(query) {
    const storageKey = `${PERSISTER_KEY_PREFIX}-${query.queryHash}`
    storage.setItem(storageKey, JSON.stringify(query))
}

const setQueryDataAndPersist = function(queryClient, queryKey, dataOrUpdateFn) {
    // Currently setQueryData is not saving to the persister https://github.com/TanStack/query/issues/6310
    // In order for the offline experience to correctly track offline updates we need to persist the data
    // So we do it manually here
    let updated_data = null
    if (typeof dataOrUpdateFn === 'function') {
        const current_data = queryClient.getQueryData(queryKey)
        updated_data = cloneDeepUnref(current_data)
        updated_data = dataOrUpdateFn(updated_data)
    } else {
        updated_data = dataOrUpdateFn
    }

    queryClient.setQueryData(queryKey, updated_data)
    const queryHash = hashKey(cloneDeepUnref(queryKey))
    const state = queryClient.getQueryState(queryKey)

    persistQuery({
        state,
        queryKey: cloneDeepUnref(queryKey),
        queryHash,
        buster: '',
    })
    return { next_data: updated_data }
}
export { persistQuery, setQueryDataAndPersist, defaultMutationFn, vueQueryPluginOptions }
