<template>
    <div
        class="documents-container"
        :class="{ no_top_padding: inGridCard, has_thumbnails: thumbnails }"
    >
        <div v-show="documents.length" class="documents-grid">
            <document
                v-for="doc in documents"
                :key="`doc_${doc.document_ref}`"
                :file-name="doc.filename"
                :document-ref="doc.document_ref"
                :file-type="doc.content_type"
                :thumbnail="thumbnails"
                @delete="remove_document(doc.document_ref)"
            />
        </div>
        <upload-area :room-id="roomId" :purpose="purpose" :type="type" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from '@/composables/fake-vuex'
import { useDocuments } from '@/composables/documents'
import Document from './Document.vue'
import UploadArea from './UploadArea.vue'
import utils from '@/shared/plugins/utils'

export default {
    name: 'Documents',
    components: {
        Document,
        UploadArea,
    },
    props: {
        purpose: {
            type: String,
            default: undefined,
        },
        roomId: {
            type: String,
            default: null,
        },
        type: {
            validator(value) {
                return ['picture', 'document', '*'].includes(value)
            },
            default: '*',
        },
        inGridCard: {
            type: Boolean,
            default: false,
        },
        thumbnails: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        uploading: false,
    }),
    setup: () => {
        const documentObjects = useDocuments(true)
        return {
            ...mapGetters(documentObjects, ['getDocuments']),
            ...mapActions(documentObjects, ['remove_document']),
        }
    },
    computed: {
        documents() {
            return this.getDocuments.filter((doc) => {
                let showDoc = true
                if (this.purpose) {
                    showDoc = showDoc && doc.purpose === this.purpose
                }
                if (this.roomId) {
                    showDoc = showDoc && doc.room_id === this.roomId
                }
                return showDoc
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.documents-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    .documents-grid {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media screen and (max-width: 999px) {
            width: 100%;
        }
    }

    &.has_thumbnails {
        gap: 24px;

        .documents-grid {
            flex-direction: row;
            gap: 12px;
        }

        @media screen and (max-width: 999px) {
            gap: 16px;

            .documents-grid {
                gap: 8px;
            }
        }
    }

    &.no_top_padding {
        padding-top: 0;
    }

    @media screen and (max-width: 999px) {
        gap: 16px;
    }
}
</style>
