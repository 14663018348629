<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        class="ers-content section results-page is-fluid pt-0 pb-0 mb-0 mt-0"
        :class="[$vssWidth < 768 && 'pt-0']"
    >
        <div class="sticky-column">
            <div class="column is-12 mt-0">
                <h1 class="title">
                    {{ getTitle }}
                </h1>
                <h1
                    v-if="(status == 'pending' || status === 'created') && wait_time"
                    class="pending-title title"
                    v-html="
                        $t(`processing_page.title_pending_${pending_range}`, {
                            waiting: wait_time,
                        })
                    "
                />
            </div>
            <div class="buttons-wrapper">
                <b-button
                    v-if="status === 'success'"
                    tag="router-link"
                    :to="{
                        name: 'ers.result',
                        params: { lang: $route.params.lang },
                        query: {
                            ...$route.query,
                        },
                    }"
                    type="is-primary"
                >
                    <span>{{ $t('processing_page.check_results') }}</span>
                </b-button>
                <template v-if="status === 'failed'">
                    <b-button tag="router-link" :to="{ name: 'ers' }" type="is-primary">
                        <span>{{ $t('dashboard.new_request.title') }}</span>
                    </b-button>
                    <b-button
                        type="is-primary"
                        @click="
                            $emit('open-summary-modal', {
                                unsubmit: true,
                            })
                        "
                    >
                        <span>{{ $t('energy.result.button.edit_input') }}</span>
                    </b-button>
                </template>
            </div>
            <img
                v-if="status === 'pending'"
                src="@/assets/img/ers/house_scanning.gif"
                class="house-img"
            />
            <img
                v-else-if="status === 'success'"
                src="@/assets/img/ers/house_basic.gif"
                class="house-img"
            />
            <img
                v-else-if="status === 'failed'"
                src="@/assets/img/ers/house_reconstruction.gif"
                class="house-img"
            />
            <img v-else src="@/assets/img/ers/house_basic.gif" class="house-img" />
        </div>
    </div>
</template>
<script>
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'
import Renderer from '@/shared/components/3DComponents/Renderer3D.vue'
import { config } from '@/shared/assets/config/config'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Submitted',
    data: () => ({
        status: 'pending',
        wait_time: null,
        simulation_ref: null,
    }),
    computed: {
        ...mapGetters('ersStore', ['getMapScene3d', 'getFeatures', 'getRequestRef']),
        getTitle() {
            if (this.status === 'success') {
                return this.$t('processing_page.title_finished')
            }
            if (this.status === 'failed') {
                return this.$t('processing_page.title_failed')
            }
            return !this.wait_time ? this.$t('processing_page.title_started') : null
        },
        pending_range() {
            if (this.wait_time > 120) return 'long'
            if (this.wait_time > 15) return 'medium'
            return 'short'
        },
    },

    async mounted() {
        setTimeout(async () => {
            const { simulation_ref } = await this.fetch_latest_simulation(
                this.getRequestRef
            )
            this.simulation_ref = simulation_ref
            this.pollStatus()
        }, 1500)
    },
    methods: {
        ...mapMutations('ersStore', ['SET_SIMULATION', 'SET_MAP_SCENE3D']),
        ...mapActions('ersStore', ['fetch_latest_simulation']),

        sleep(delay) {
            return new Promise((resolve) => setTimeout(resolve, delay))
        },
        async pollStatus() {
            try {
                const { data } = await this.$axios.get(
                    utils.urlJoin(
                        this.$config.VALUATION_API_URL,
                        `energy-simulation/${this.simulation_ref}?details=status`
                    )
                )
                const { status, wait_time } = data
                this.wait_time = parseInt(wait_time)
                this.status = status

                if (!['success', 'failed'].includes(status)) {
                    await this.sleep(5000)
                    this.pollStatus()
                }
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.answer-item {
    display: grid;
    grid-template-columns: 42px auto;
    gap: 1rem;
    margin-bottom: 1rem;

    &_text {
        align-self: center;
        line-height: 15px;
    }
}

.sticky-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    * {
        text-align: center;
    }
}

.buttons-wrapper {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.renderer {
    width: 90vw !important;
    height: calc(90vw / 1.5) !important;
    @media screen and (min-width: 768px) {
        width: 40vw !important;
        height: calc(40vw / 1.5) !important;
    }
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
<style lang="scss">
.pending-title {
    i {
        color: var(--primary);
        font-style: normal;
    }
}
</style>
