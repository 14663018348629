<template>
    <Card class="consumption" :class="{ 'consumption--on-report': onReport }">
        <div class="consumption__title">
            {{ $t('energy.result.consumption.title') }}
        </div>

        <div class="consumption-content">
            <div v-if="getSimulationStatus === 'finished'" class="consumption-labels">
                <div
                    v-for="data in getConsumptionData"
                    :key="data.fuelType"
                    class="consumption__chart chart"
                >
                    <div class="chart__label label">
                        <div class="label__icon">
                            <svg-icon
                                :icon="fuels(data.fuelType).icon"
                                width="20"
                                height="20"
                                color="var(--color-primary)"
                            />
                        </div>
                        <div class="label__text h5">
                            <strong>
                                {{ $t(`value.${fuels(data.fuelType).value}`) }}
                            </strong>
                        </div>
                    </div>
                </div>
            </div>

            <div class="consumption-charts">
                <div
                    v-for="data in getConsumptionData"
                    :key="data.fuelType"
                    class="chart__graph graph"
                >
                    <div class="graph__bar bar">
                        <div class="graph__bar-labels">
                            <p>{{ $t('energy.result.consumption.low') }}</p>
                            <p>{{ $t('energy.result.consumption.hi') }}</p>
                        </div>
                        <div
                            :style="{
                                left: `${data.chartData.house}%`,
                                zIndex: 0,
                            }"
                            class="bar__dot-wrapper"
                        >
                            <b-tooltip
                                always
                                class="consumption-tooltip"
                                position="is-bottom"
                                :label="$t('energy.result.consumption.you')"
                            >
                                <div class="bar__dot"></div>
                            </b-tooltip>
                        </div>
                        <div
                            :style="{
                                left: `${data.chartData.med}%`,
                                zIndex: 0,
                            }"
                            class="bar__dot-wrapper"
                        >
                            <b-tooltip
                                always
                                class="consumption-tooltip"
                                :label="$t('energy.result.consumption.med')"
                            >
                                <div class="bar__dot"></div>
                            </b-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from './Card.vue'
import { mapGetters } from 'vuex'
import utils from '@/shared/plugins/utils'

export default {
    name: 'Consumption',
    components: { Card },
    props: {
        onReport: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters('ersStore', [
            'getFeatures',
            'getConsumptionKnown',
            'getRegion',
            'getHeatingFuel',
            'getSimulationStatus',
        ]),
        getConsumptionData() {
            const consumptionKnown = this.getConsumptionKnown
            if (consumptionKnown === null) return

            const currentRegion = this.getRegion
            const consumptions = !consumptionKnown
                ? this.getFeatures.estimated_consumptions
                : this.getFeatures.known_consumptions
            const data = consumptions.map((fuel) => {
                const fuelType = fuel.consumption_fuel
                const unit = fuel.unit === 'kwh' ? 'kwh' : 'canonical_unit'
                const isFuelElectricity =
                    fuelType === 'electricity' && this.getHeatingFuel === 'electricity'
                const regionData = utils.consumptionsData(
                    unit,
                    currentRegion,
                    fuelType,
                    isFuelElectricity
                )

                let houseConsumption

                if (!consumptionKnown) {
                    let estimate
                    if (fuel.estimate === 'low') {
                        estimate = 'min'
                    } else if (fuel.estimate === 'medium') {
                        estimate = 'med'
                    } else {
                        estimate = 'max'
                    }
                    houseConsumption = regionData[estimate]
                } else {
                    houseConsumption = fuel.amount
                }
                const chartData = {
                    med: utils.convertRange(
                        regionData.med,
                        [regionData.min, regionData.max],
                        [0, 100]
                    ),
                    house: utils.clamp(
                        utils.convertRange(
                            houseConsumption,
                            [regionData.min, regionData.max],
                            [0, 100]
                        ),
                        2,
                        98
                    ),
                }
                return {
                    fuelType,
                    regionData,
                    houseConsumption,
                    chartData,
                }
            })
            return data
        },
    },
    methods: {
        fuels(fuel) {
            return [
                {
                    value: 'gas',
                    icon: 'heating-fuel-gas',
                },
                {
                    value: 'oil',
                    icon: 'heating-fuel-oil',
                },
                {
                    value: 'wood',
                    icon: 'heating-fuel-wood',
                },
                {
                    value: 'pellets',
                    icon: 'heating-fuel-pellets',
                },
                {
                    value: 'coal',
                    icon: 'heating-fuel-coal',
                },
                {
                    value: 'district_heating',
                    icon: 'heating-fuel-district-heating',
                },
                {
                    value: 'electricity',
                    icon: 'heating-fuel-electric',
                },
            ].find((type) => fuel === type.value)
        },
    },
}
</script>

<style lang="scss">
.b-tooltip.consumption-tooltip .tooltip-content {
    background: #162d49 !important;
    margin-block: 4px;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    &:before {
        border-top-color: #162d49 !important;
        border-bottom-color: #162d49 !important;
    }
}
</style>

<style lang="scss" scoped>
.consumption {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-inline: auto;
    &-content {
        display: flex;
        margin-block: 2rem;
    }
    &-charts {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 2rem;
    }
}

.consumption-labels {
    max-width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 2rem;
}

@media (min-width: 1200px) {
    .consumption {
        margin-inline: unset;
        margin-block-start: unset;
    }
}

.consumption__chart {
    display: flex;
}

.consumption__title {
    color: #001837;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.tooltip {
    max-width: 20px;
    max-height: 20px;
    margin-inline-start: 10px;
    font-size: 13px;
}

.title__tooltip {
    margin-block: auto;
}

.chart__label {
    display: flex;
    flex-direction: column;
    margin-top: 23px;
    margin-inline: auto;
}

.label__icon {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: rgba(0, 156, 255, 0.2);
    display: grid;
    place-items: center;
    margin-inline: auto;
}

.svg-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    justify-content: center;

    position: relative;
    overflow: visible;
}

.label__text {
    color: #001837;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-block: 0.75rem;
    text-align: center;
}

.chart__graph {
    min-height: 10px;
    min-width: 10px;
    flex-grow: 1;
    display: flex;
    margin-inline-start: 30px;
}

.graph__bar {
    width: 210px;
    height: 8px;
    background-image: linear-gradient(to right, #52d860 0%, #ffc700 52.08%, #ff1515 100%);
    margin-block: auto;
    border-radius: 4px;
    position: relative;
    &-labels {
        display: flex;
        position: absolute;
        min-width: 100%;
        justify-content: space-between;
        transform: translateY(-110%);
        color: #798da6;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.bar__dot {
    width: 6px;
    height: 8px;
    top: -4px;
    background: #001837;
    position: absolute;
    clip-path: circle(closest-side);
    transform: translateX(-50%);
}

.bar__dot-wrapper {
    position: absolute;
    top: -14px;
}

.consumption--on-report {
    .graph__bar {
        width: 90px;

        .bar__dot {
            width: 6px;
            height: 8px;
            top: -5px;
            background: #001837;
            position: absolute;
            clip-path: circle(closest-side);
            transform: translateX(-50%);
        }

        .bar__dot-wrapper {
            position: absolute;
            top: -4px;
        }
    }
}

@media print {
    .consumption {
        &-content {
            margin-top: 4rem;
        }

        &-labels,
        &-charts {
            gap: 6rem;
        }
    }
    .chart__label {
        margin-top: 0;

        .label__text {
            padding-block: 0.1rem;
        }
        .label__icon {
            width: 2rem;
            height: 2rem;

            .svg-icon {
                height: 1rem !important;
                width: 1rem !important;
            }
        }
    }

    .graph__bar {
        width: 2cm;

        .bar__dot {
            width: 6px;
            height: 8px;
            top: -5px;
            background: #001837;
            position: absolute;
            clip-path: circle(closest-side);
            transform: translateX(-50%);
        }

        .bar__dot-wrapper {
            position: absolute;
            top: -4px;
        }
    }
}
</style>
