<i18n>
{
    "en-BE": {
        "basic_info": {
            "title": "Basic info",
            "building_type": {
                "label": "Building Type",
                "options": {
                    "house": "House",
                    "apartment": "Apartment",
                    "new_house": "New house",
                    "new_apartment": "New apartment",
                    "construction_plot": "Construction plot"
                }
            },
            "building_found": "Building found",
            "level": "Level",
            "touching_sides": "Number of touching sides",
            "renovations": {
                "label": "Renovations",
                "warning": "Renovations costs > 15% of AVM price"
            }
        },
        "state": {
            "title": "State",
            "f_epc_label": "EPC Label"
        },
        "size": {
            "title": "Size"
        },
        "apartment_features": {
            "title": "Apartment features"
        },
        "annexes": {
            "title": "Annexes"
        },
        "other": {
            "title": "Other",
            "f_dist_building_flood": "Building distance to nearest flood zone",
            "f_dist_parcel_flood": "Parcel distance to nearest flood zone",
            "f_matched_entities": "Matched economic entities"
        },
        "yes": "Yes",
        "no": "No"
    },
    "en-FR": {
        "basic_info": {
            "title": "Basic info",
            "building_type": {
                "label": "Building Type",
                "options": {
                    "house": "House",
                    "apartment": "Apartment",
                    "new_house": "New house",
                    "new_apartment": "New apartment",
                    "construction_plot": "Construction plot"
                }
            },
            "building_found": "Building found",
            "level": "Level",
            "touching_sides": "Number of touching sides",
            "renovations": {
                "label": "Renovations",
                "warning": "Renovations costs > 15% of AVM price"
            }
        },
        "state": {
            "title": "State",
            "f_epc_label": "EPC Label"
        },
        "size": {
            "title": "Size"
        },
        "apartment_features": {
            "title": "Apartment features"
        },
        "annexes": {
            "title": "Annexes"
        },
        "other": {
            "title": "Other",
            "f_dist_building_flood": "Building distance to nearest flood zone",
            "f_dist_parcel_flood": "Parcel distance to nearest flood zone",
            "f_matched_entities": "Matched economic entities"
        },
        "yes": "Yes",
        "no": "No"
    },
    "en-NL": {
        "basic_info": {
            "title": "Basic info",
            "building_type": {
                "label": "Building Type",
                "options": {
                    "house": "House",
                    "apartment": "Apartment",
                    "new_house": "New house",
                    "new_apartment": "New apartment",
                    "construction_plot": "Construction plot"
                }
            },
            "building_found": "Building found",
            "level": "Level",
            "touching_sides": "Number of touching sides",
            "renovations": {
                "label": "Renovations",
                "warning": "Renovations costs > 15% of AVM price"
            }
        },
        "state": {
            "title": "State",
            "f_epc_label": "EPC Label"
        },
        "size": {
            "title": "Size"
        },
        "apartment_features": {
            "title": "Apartment features"
        },
        "annexes": {
            "title": "Annexes"
        },
        "other": {
            "title": "Other",
            "f_dist_building_flood": "Building distance to nearest flood zone",
            "f_dist_parcel_flood": "Parcel distance to nearest flood zone",
            "f_matched_entities": "Matched economic entities"
        },
        "yes": "Yes",
        "no": "No"
    }
}
</i18n>
<template>
    <div class="is-w100">
        <b-loading v-if="isLoading" />
        <form v-else>
            <h2 class="is-size-4 has-text-black m-0 has-text-weight-medium">
                {{ $t('basic_info.title') }}
            </h2>
            <div class="is-flex is-justify-content-start is-align-items-start is-w100">
                <b-field :label="$t('basic_info.building_type.label')" class="mr-4 pl-0">
                    <b-select v-model="request.features.f_building_type" disabled>
                        <option value="apartment">
                            {{ $t('basic_info.building_type.options.apartment') }}
                        </option>
                        <option value="house">
                            {{ $t('basic_info.building_type.options.house') }}
                        </option>
                        <option value="new_apartment">
                            {{ $t('basic_info.building_type.options.new_apartment') }}
                        </option>
                        <option value="new_house">
                            {{ $t('basic_info.building_type.options.new_house') }}
                        </option>
                        <option value="construction_plot">
                            {{ $t('basic_info.building_type.options.construction_plot') }}
                        </option>
                    </b-select>
                </b-field>
                <b-field
                    v-if="is_plot"
                    :label="$t('features.f_parcel_area')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                >
                    <b-input v-model="request.features.f_parcel_area" type="number" />
                </b-field>
                <b-field
                    :label="$t('basic_info.building_found')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                >
                    <b-input
                        disabled
                        :value="request.features.buildingnotfound ? 'No' : 'Yes'"
                    />
                </b-field>
                <b-field :label="$t('basic_info.level')" class="is-flex-grow-1">
                    <b-input type="number" disabled :value="+request.features.level" />
                </b-field>
            </div>
            <div
                v-if="is_new || is_plot"
                class="is-flex is-justify-content-start is-align-items-start is-w100"
            >
                <b-field
                    :label="$t('features.parcel_cost')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                >
                    <b-input
                        v-model="request.features.parcel_cost"
                        type="number"
                        disabled
                    />
                </b-field>
                <b-field
                    v-if="!is_plot"
                    :label="$t('features.construction_cost')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                >
                    <b-input
                        v-model="request.features.construction_cost"
                        type="number"
                        disabled
                    />
                </b-field>
            </div>
            <div
                v-if="is_building && !is_new"
                class="is-flex is-justify-content-start is-align-items-start is-w100 mb-5"
            >
                <b-field
                    v-if="is_house"
                    :label="$t('basic_info.touching_sides')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                >
                    <b-input v-model="request.features.f_touching_sides" type="number" />
                </b-field>
                <b-field
                    :label="$t('basic_info.renovations.label')"
                    class="is-flex-grow-1"
                    :type="request.features.renovation_cost ? 'is-danger' : null"
                >
                    <div class="is-flex is-flex-direction-column">
                        <b-input
                            :value="`${withSpaces(request.features.renovation_cost)}€`"
                            disabled
                        />
                        <h3
                            v-if="hasRenovationWarning"
                            class="is-size-5 has-text-weight-bold has-text-danger is-align-self-center"
                        >
                            {{ $t('basic_info.renovations.warning') }}
                        </h3>
                    </div>
                </b-field>
            </div>
            <div>
                <div
                    v-if="isErsReportPresent"
                    class="is-flex is-justify-content-start is-align-items-start is-w100 mb-5"
                >
                    <b-field
                        :label="$t('features.energetic_renovation_cost')"
                        class="is-flex-grow-1 mr-4 ml-0 pl-0"
                    >
                        <b-input
                            v-model="request.features.energetic_renovation_cost"
                            type="number"
                        />
                    </b-field>
                    <b-field
                        :label="$t('features.energetic_renovation_added_value')"
                        class="is-flex-grow-1 mr-4 ml-0 pl-0"
                    >
                        <b-input
                            v-model="request.features.energetic_renovation_added_value"
                            type="number"
                        />
                    </b-field>
                </div>
            </div>
            <template v-if="is_building">
                <h2 class="is-size-4 has-text-black m-0 has-text-weight-medium">
                    {{ $t('state.title') }}
                </h2>
                <div
                    class="is-flex is-justify-content-start is-align-items-start is-w100 mb-5"
                >
                    <b-field
                        v-if="isEpcLabel"
                        :label="$t('state.f_epc_label')"
                        class="is-flex-grow-1 mr-4 ml-0 pl-0"
                    >
                        <b-autocomplete
                            v-model="request.features.f_epc_label"
                            :open-on-focus="true"
                            :data="epc_labels"
                        />
                    </b-field>
                    <b-field
                        :label="$t('features.f_epc')"
                        class="is-flex-grow-1 mr-4 ml-0 pl-0"
                    >
                        <b-input
                            v-model="request.features.f_epc"
                            type="number"
                            :step="0.1"
                            @input="SET_EPC"
                        />
                    </b-field>
                    <b-field
                        :label="$t('features.f_construction_year')"
                        class="is-flex-grow-1"
                    >
                        <b-input
                            v-model="request.features.f_construction_year"
                            type="number"
                        />
                    </b-field>
                </div>
            </template>
            <template v-if="is_house">
                <h2 class="is-size-4 has-text-black m-0 has-text-weight-medium">
                    {{ $t('size.title') }}
                </h2>
                <div
                    class="is-flex is-justify-content-start is-align-items-start is-w100"
                >
                    <b-field
                        :label="$t('features.f_parcel_area')"
                        class="is-flex-grow-1 mr-4 ml-0 pl-0"
                    >
                        <b-input
                            v-model="request.features.f_parcel_area"
                            type="number"
                            step=".01"
                        />
                    </b-field>
                    <b-field :label="$t('features.f_garden_area')" class="is-flex-grow-1">
                        <b-input
                            v-model="request.features.f_garden_area"
                            type="number"
                            step=".01"
                        />
                    </b-field>
                </div>
            </template>
            <template v-if="is_building">
                <div
                    class="is-flex is-justify-content-start is-align-items-start is-w100"
                >
                    <b-field
                        :label="$t('features.f_living_area')"
                        class="is-flex-grow-1 mr-4 ml-0 pl-0"
                    >
                        <b-input
                            v-model="request.features.f_living_area"
                            type="number"
                            step=".01"
                        />
                    </b-field>
                    <b-field
                        v-if="is_house || (is_apartment && !is_new)"
                        :label="$t('features.f_building_area')"
                        class="is-flex-grow-1"
                    >
                        <b-input
                            v-model="request.features.f_building_area"
                            :disabled="is_apartment"
                            type="number"
                            step=".01"
                        />
                    </b-field>
                </div>
                <div
                    class="is-flex is-justify-content-start is-align-items-start is-w100 mb-5"
                >
                    <b-field
                        v-if="request.features.f_inner_volume"
                        :label="$t('features.f_approx_living_area')"
                        class="is-flex-grow-1"
                    >
                        <b-input
                            :value="
                                Math.floor(
                                    Math.floor(request.features.f_inner_volume) / 2.8
                                )
                            "
                            type="number"
                            step=".01"
                            disabled
                        />
                    </b-field>
                    <b-field
                        v-else
                        :label="$t('features.f_approx_living_area')"
                        class="is-flex-grow-1"
                    >
                        <b-input value="N/A" type="text" disabled />
                    </b-field>
                </div>
            </template>
            <template v-if="is_apartment">
                <h2 class="is-size-4 has-text-black m-0 has-text-weight-medium">
                    {{ $t('apartment_features.title') }}
                </h2>
                <div
                    class="is-flex is-justify-content-start is-align-items-start is-w100"
                >
                    <b-field
                        :label="$t('features.f_bottom_floor')"
                        class="is-flex-grow-1 mr-4 ml-0 pl-0"
                    >
                        <b-input
                            v-model="request.features.f_bottom_floor"
                            type="number"
                        />
                    </b-field>
                    <b-field
                        :label="$t('features.f_top_floor')"
                        class="is-flex-grow-1 mr-4 ml-0 pl-0"
                    >
                        <b-input v-model="request.features.f_top_floor" type="number" />
                    </b-field>
                </div>
                <range-selector
                    v-model="request.features.f_floors"
                    class="p-0 mb-2"
                    :label="$t('features.f_floors')"
                    :range-list="floor_list"
                    :type="
                        request.features.f_floors.length > 1 ? 'is-danger' : 'is-primary'
                    "
                    @input="updateFloors"
                />
                <div
                    class="is-flex is-justify-content-start is-align-items-start is-w100"
                >
                    <b-field
                        :label="$t('features.f_bedrooms')"
                        class="is-flex-grow-1 mr-4 ml-0 pl-0"
                    >
                        <b-input v-model="request.features.f_bedrooms" type="number" />
                    </b-field>
                    <b-field
                        :label="$t('features.f_n_closed_garage')"
                        class="is-flex-grow-1 mr-4 ml-0 pl-0"
                        :type="request.features.f_n_closed_garage ? 'is-danger' : null"
                    >
                        <b-input
                            v-model="request.features.f_n_closed_garage"
                            type="number"
                        />
                    </b-field>
                    <b-field
                        :label="$t('features.f_n_parking_spot')"
                        class="is-flex-grow-1 mr-4 ml-0 pl-0"
                        :type="request.features.f_n_parking_spot ? 'is-danger' : null"
                    >
                        <b-input
                            v-model="request.features.f_n_parking_spot"
                            type="number"
                        />
                    </b-field>
                </div>
                <div
                    class="is-flex is-justify-content-space-between is-align-items-start is-w100"
                >
                    <radio-selector
                        v-model="request.features.f_cellar_attic"
                        :label="$t('features.f_cellar_attic')"
                        :options="[
                            {
                                label: $t('yes'),
                                value: true,
                            },
                            {
                                label: $t('no'),
                                value: false,
                            },
                        ]"
                    />
                    <radio-selector
                        v-model="request.features.f_balcony_present"
                        :label="$t('features.f_balcony_present')"
                        :options="[
                            {
                                label: $t('yes'),
                                value: true,
                            },
                            {
                                label: $t('no'),
                                value: false,
                            },
                        ]"
                    />
                    <radio-selector
                        v-model="request.features.f_garden_common"
                        :label="$t('features.f_garden_common')"
                        :options="[
                            {
                                label: $t('yes'),
                                value: true,
                            },
                            {
                                label: $t('no'),
                                value: false,
                            },
                        ]"
                    />
                    <radio-selector
                        v-model="request.features.f_garden_private"
                        :label="$t('features.f_garden_private')"
                        :options="[
                            {
                                label: $t('yes'),
                                value: true,
                            },
                            {
                                label: $t('no'),
                                value: false,
                            },
                        ]"
                    />
                </div>
            </template>
            <template v-if="is_house">
                <h2 class="is-size-4 has-text-black m-0 has-text-weight-medium mt-5">
                    {{ $t('annexes.title') }}
                </h2>
                <div
                    class="is-flex is-justify-content-start is-align-items-start is-w100"
                >
                    <h5 class="is-flex-grow-1 mr-4 ml-0 pl-0">
                        {{ $t('features.f_x_annexes') }}:
                        {{ request.features.f_x_annexes }}
                    </h5>
                    <h5 class="is-flex-grow-1">
                        {{ $t('features.f_annexes_area') }}:
                        {{ Math.round(request.features.f_annexes_area * 100) / 100 || 0 }}
                    </h5>
                </div>
                <h2 class="is-size-4 has-text-black m-0 has-text-weight-medium mt-5">
                    {{ $t('other.title') }}
                </h2>
            </template>
            <div class="columns">
                <div v-if="request.features.f_flood_risk" class="column is-6">
                    <h5
                        :class="
                            request.features.f_flood_risk.toLowerCase() !== 'none' &&
                                'has-text-danger has-text-weight-bold'
                        "
                    >
                        {{ $t('features.f_flood_risk') }}:
                        {{ $t(`value.${request.features.f_flood_risk.toLowerCase()}`) }}
                        <b-icon
                            v-if="request.features.f_flood_risk.toLowerCase() !== 'none'"
                            type="is-danger"
                            icon="alert-circle"
                        />
                    </h5>
                    <h5>
                        {{ $t('other.f_dist_building_flood') }}:
                        {{ request.features.f_dist_building_flood }} m
                    </h5>
                    <h5>
                        {{ $t('other.f_dist_parcel_flood') }}:
                        {{ request.features.f_dist_parcel_flood }} m
                    </h5>
                </div>
                <div
                    v-if="
                        is_building &&
                            (request.features.c_economic_activity_level ||
                                request.features.f_matched_entities)
                    "
                    class="column is-6"
                >
                    <h5 v-if="request.features.c_economic_activity_level">
                        {{ $t('features.c_economic_activity_level') }}:
                        {{ $t(`value.${request.features.c_economic_activity_level}`) }}
                    </h5>
                    <h5 v-if="request.features.f_matched_entities">
                        {{ $t('other.f_matched_entities') }}:
                        {{ request.features.f_matched_entities.join(', ') }}
                    </h5>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import RangeSelector from '@/components/elements/RangeSelector.vue'
import RadioSelector from '@/components/elements/RadioSelector.vue'
import utils from '@/shared/plugins/utils'
import enums from '@/shared/plugins/enums'

export default {
    name: 'DvmDescription',
    components: {
        RangeSelector,
        RadioSelector,
    },
    data() {
        return {
            isLoading: true,
            request: {},
            valuation_request_ref: null,
            floors: [],
        }
    },
    computed: {
        ...mapGetters(['getFullStore']),
        ...mapGetters('auth', ['accessibleModules']),
        ...mapGetters('valuationStore', ['getDocuments']),
        isErsReportPresent() {
            const docPurposes = this.getDocuments.map((doc) => doc.purpose)
            return docPurposes.includes('ers_report')
        },
        epc_labels() {
            return Object.keys(
                enums.epc_mapping[
                    this.request.features.region ? this.request.features.region : 'BE-VLG'
                ]
            )
        },
        building_type() {
            return this.request.features.f_building_type
        },
        is_apartment() {
            return utils.is_apartment(this.building_type)
        },
        is_house() {
            return utils.is_house(this.building_type)
        },
        is_building() {
            return utils.is_building(this.building_type)
        },
        is_plot() {
            return utils.is_plot(this.building_type)
        },
        is_new() {
            return utils.is_new(this.building_type)
        },
        isEpcLabel() {
            return !!this.request.features.f_epc_label
        },
        floor_list() {
            let l = []
            for (
                let i = this.request.features?.f_bottom_floor || 0;
                i <= this.request.features.f_top_floor;
                i += 1
            ) {
                l.push(i)
            }
            return l
        },
        hasRenovationWarning() {
            return (
                this.is_building &&
                this.request.features.renovation_cost &&
                this.request.features.renovation_cost >=
                    this.request.avm_transaction.body.estimation.asking_price_q50 * 0.15
            )
        },
    },
    watch: {
        'request.features': {
            deep: true,
            handler(val) {
                if (this.is_house) {
                    val.f_parcel_area = +val.f_parcel_area
                    val.f_building_area =
                        val.f_building_area === undefined ? 0 : +val.f_building_area
                    val.f_garden_area = +val.f_garden_area
                    val.f_touching_sides = +val.f_touching_sides
                }
                if (this.is_apartment) {
                    val.f_bedrooms = +val.f_bedrooms
                    val.f_bottom_floor = +val.f_bottom_floor
                    val.f_top_floor = +val.f_top_floor
                    val.f_n_closed_garage = +val.f_n_closed_garage
                    val.f_n_parking_spot = +val.f_n_parking_spot
                }
                if (this.is_building) {
                    val.f_epc = +val.f_epc
                    val.f_epc_numeric = +val.f_epc
                    val.f_construction_year = +val.f_construction_year
                    val.f_living_area = +val.f_living_area
                }
                if (this.is_plot) {
                    val.f_parcel_area = +val.f_parcel_area
                }
                if (val.energetic_renovation_added_value) {
                    val.energetic_renovation_added_value = +val.energetic_renovation_added_value
                }
                if (val.energetic_renovation_cost) {
                    val.energetic_renovation_cost = +val.energetic_renovation_cost
                }
                this.SET_FEATURES(val)
            },
        },
    },
    mounted() {
        this.request = this.getFullStore.valuationStore.Address
        if (
            this.getFullStore.valuationStore.Address.features.f_building_type ===
            'apartment'
        ) {
            this.floors = [...this.getFullStore.valuationStore.Address.features.f_floors]
        }
        this.valuation_request_ref = this.getFullStore.valuationStore.valuation_request_ref
        this.request.features.f_parcel_area =
            Math.round(+this.request.features.f_parcel_area * 100) / 100
        this.request.features.f_building_area =
            Math.round(+this.request.features.f_building_area * 100) / 100
        this.request.features.f_garden_area =
            Math.round(+this.request.features.f_garden_area * 100) / 100

        this.isLoading = false
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_FEATURES', 'SET_EPC']),
        updateFloors(floors) {
            this.request.features.f_floors = floors
        },
        withSpaces(number) {
            return utils.numberWithSpaces(number)
        },
    },
}
</script>
