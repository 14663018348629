<template>
    <div class="valuer-settings">
        <section class="container is-max-desktop">
            <div v-if="isWarningMessage || !cronofyUser" class="warning">
                <div class="warning__icon">
                    <img src="@/assets/icons/ic-warning.svg" alt="" />
                </div>
                <p class="warning__text">
                    {{ $t('valuer_tool.valuer_settings.availability_warning') }}
                </p>
            </div>
            <b-tabs
                v-model="valuerTab"
                position="is-centered"
                class="block"
                :destroy-on-hide="true"
            >
                <b-tab-item :label="$t('valuer_tool.valuer.info')" value="info">
                    <valuer-info-tab :user-id="effectiveUserId" />
                </b-tab-item>
                <b-tab-item
                    :label="$t('valuer_tool.valuer.availability')"
                    value="availability"
                >
                    <progress
                        v-if="loading || (!cronofyUser && !cronofyRedirect)"
                        class="progress is-small"
                        max="100"
                    />
                    <template v-else>
                        <CronofySync
                            v-if="cronofyAuthUrl"
                            :element-token="
                                cronofyUser ? cronofyUser.cronofy_element_token : null
                            "
                            :authorization-url="cronofyAuthUrl"
                        />
                        <AvailabilityRules
                            v-if="cronofyUser"
                            :cronofy-user="cronofyUser"
                            @on-rules-change="handleRules"
                        />
                    </template>
                </b-tab-item>
                <b-tab-item
                    :label="$t('valuer_tool.valuer.appointments')"
                    value="appointments"
                >
                    <AppointmentSummary :display-list="true" />
                </b-tab-item>
            </b-tabs>
        </section>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import utils from '@/shared/plugins/utils'
import { mapGetters } from 'vuex'
import CronofySync from './cronofyElements/CronofySync'
import AvailabilityRules from './AvailabilityRules'
import AppointmentSummary from '../request/ovm/Summary'
import ValuerInfoTab from './ValuerInfoTab.vue'

export default {
    name: 'ValuerSettings',
    components: {
        CronofySync,
        AvailabilityRules,
        AppointmentSummary,
        ValuerInfoTab,
    },
    data: () => ({
        cronofyUser: null,
        cronofyAuthUrl: null,
        loading: true,
        valuerTab: 'info',
        rules: [],
        isWarningMessage: false,
    }),
    computed: {
        ...mapGetters('auth', ['userId', 'getAccreditations']),
        effectiveUserId() {
            return this.$route.query.id || this.userId
        },
    },
    watch: {
        rules(newVal) {
            this.isWarningMessage = newVal.length === 0
        },
        valuerTab(newVal) {
            if (this.$route.query.tab !== newVal)
                this.$router.replace({ query: { tab: newVal } })
        },
    },
    mounted() {
        this.loadCronofyUser()
            .then((res) => {
                // if user is not logged into Cronofy, API returns 307
                // API always returns cronofy_url
                const url = new URL(res.data.cronofy_url)
                const callbackPath = this.$router.resolve({ name: 'cronofy-callback' })
                const callbackUrl = new URL(callbackPath.href, window.location.origin)
                url.searchParams.append('redirect_uri', callbackUrl.href)

                // Backend sends an encrypted state that we wrap in our own state:
                const backendState = url.searchParams.get('state')
                url.searchParams.delete('state')

                // TODO: do we want to encrypt the redirection data?
                // Rationale for not: this is not sensitive info, and tampering with it only affects the frontend
                url.searchParams.append(
                    'state',
                    JSON.stringify({
                        name: 'valuation.valuer-settings',
                        query: {
                            tab: 'availability',
                        },
                        backend_state: backendState,
                    })
                )
                this.cronofyAuthUrl = url.href

                // if user is logged into Cronofy, API returns 200 with user data
                if (res.status === 200) {
                    this.cronofyUser = res.data
                }
            })
            .finally(() => {
                this.loading = false
                this.isWarningMessage = this.cronofyUser?.availability_rules.length === 0
            })

        if (this.$route.query.tab) {
            this.valuerTab = this.$route.query.tab
        }
    },
    methods: {
        loadCronofyUser() {
            return this.$axios.get(
                utils.urlJoin(this.$config.VALUATION_API_URL, [
                    'availability',
                    'user',
                    this.effectiveUserId,
                ]),
                { validateStatus: (code) => code === 200 || code === 307 }
            )
        },
        handleRules(rules) {
            this.rules = rules
        },
    },
}
</script>
<style lang="scss" scoped>
.valuer-settings {
    background: #f9fafc;
    padding-block: 3rem;
}

.warning {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    background: rgba(255, 170, 0, 0.2);
    margin: auto;
    margin-bottom: 1.5rem;
    max-width: 40rem;
    &__icon {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.52081rem;
        justify-content: center;
        align-items: center;
        gap: 0.52081rem;
        border-radius: 4.16669rem;
        background: rgba(255, 170, 0, 0.2);
        img {
            flex-shrink: 0;
            fill: #fa0;
        }
    }
    &__text {
        color: #001837;
        text-align: center;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.125rem; /* 150% */
    }
}
</style>
